<template>
  <div>
    <div class="mobilTabNavigation">
      <a href="#" class="tabLink active">
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
    </div>
    <h2 class="headline display-2">Standortdaten</h2>
    <div class="formGrid">
      <div class="w-full xl:w-1/3">
        <img :src="`${imgFolder}deKarte.jpg`" />
      </div>
      <div class="w-full xl:w-1/3">
        <p>
          <label>Objektbezeichnung - Bauvorhaben *</label>
          <input type="text" v-model="location.project" tabindex="1" />
        </p>
        <p>
          <label>Straße & Hausnummer</label>
          <input type="text" v-model="location.street" tabindex="3" />
        </p>
        <p class="footnote">
          Felder die mit einem * markiert sind, sind Pflichfelder
        </p>
      </div>
      <div class="w-full xl:w-1/3">
        <p>
          <label>Bauherr</label>
          <input type="text" v-model="location.builder" tabindex="2" />
        </p>
        <p>
          <span class="flex">
            <label class="w-full">PLZ / Ort *</label>
          </span>
          <span class="flex">
            <Multiselect
              v-model="zipTown"
              :filter-results="false"
              class="zipSelect"
              tabindex="4"
              :min-chars="2"
              :resolve-on-load="false"
              placeholder="Geben sie ihre Postleitzahl ein"
              noOptionsText="Die Liste ist leer"
              :delay="0"
              ref="zipSelect"
              :caret="false"
              :searchable="true"
              :object="true"
              @clear="clearMe()"
              :options="
                async function (query) {
                  return fetchZip(query);
                }
              "
              @select="setValues"
            />
          </span>
        </p>
        <p
          v-if="
            this.location.zip !== null &&
            (this.location.zip.startsWith('17') ||
              this.location.zip.startsWith('18') ||
              this.location.zip.startsWith('19') ||
              this.location.zip.startsWith('2'))
          "
        >
          <label>
            <input
              type="radio"
              v-model="location.terrainCategory"
              tabindex="5"
              value="1"
              @click="checkTerrain(1)"
            />{{ textLabel[this.location.windloadzone] }}<br /><span
              class="footnote"
              >* Küste: DIN EN 1991-1-4, Streifen mit 5 km Breite entlang der
              Küste landeinwärts</span
            ></label
          >
        </p>
        <p
          v-if="
            this.location.windloadzone !== null &&
            this.location.windloadzone == 4
          "
        >
          <label>
            <input
              type="radio"
              v-model="location.terrainCategory"
              tabindex="6"
              value="2"
              @click="checkTerrain(2)"
            />Inseln der Nordsee</label
          >
        </p>
      </div>
    </div>
    <div class="w-full btnWrapper">
      <button
        @click="$emit('changeTab', { currentTab: 'tab2', type: 'next' })"
        class="nextBtn"
        :disabled="nextClick"
        tabindex="6"
      >
        Weiter
      </button>
    </div>
    <div class="mobilTabNavigation">
      <a href="#" class="tabLink">
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      zipTown: null,
      textLabel: {
        2: "Küste und Inseln der Ostsee",
        3: "Küste und Inseln der Ostsee",
        4: "Küste der Nord- und Ostsee und Inseln der Ostsee",
      },
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
    };
  },
  computed: {
    location: {
      get() {
        return this.$store.getters.getLocation;
      },
      set() {
        this.$store.commit("updateLocation", this.location);
      },
    },
    nextClick() {
      let isNotValid = false;
      if (this.location.project === null || this.location.project === "") {
        isNotValid = true;
      }
      if (
        this.location.zip === null ||
        this.location.zip === "" ||
        this.location.zip.length !== 5
      ) {
        isNotValid = true;
      }
      if (this.location.town === null || this.location.town === "") {
        isNotValid = true;
      }
      return isNotValid;
    },
  },
  created() {
    this.zipTown = {
      value: this.location.zip,
      town: this.location.town,
      label: this.location.zip + " " + this.location.town,
    };
  },
  methods: {
    async fetchZip(query) {
      const response = await fetch(
        process.env.VUE_APP_PATH + "get/plz/" + query
      );
      const data = await response.json();
      return data;
    },
    setValues() {
      // console.log(this.zipTown.label);
      this.location.zip = this.zipTown.value;
      this.location.town = this.zipTown.town;
      this.location.windloadzone = this.zipTown.windloadzone;
      this.location.terrainCategory = null;
    },
    clearMe() {
      this.location.zip = null;
      this.location.town = null;
      this.location.terrainCategory = null;
      this.location.windloadzone = null;
    },
    checkTerrain(_val) {
      if (_val == this.location.terrainCategory) {
        this.location.terrainCategory = null;
      }
    },
  },
};
</script>

<style lang="scss"></style>
