<template>
  <div class="contentWrapper">
    <div class="tabNavigation">
      <a
        href="#"
        class="tabLink"
        :class="[
          activeTab === 'tab1' ? 'active' : '',
          parseInt(this.activeTab.substring(3, 4)) > 1 ? 'done' : '',
        ]"
        @click="activateTab({ currentTab: 'tab1', type: 'tab' })"
      >
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
      <a
        href="#"
        class="tabLink"
        :class="[
          activeTab === 'tab2' ? 'active' : '',
          parseInt(this.activeTab.substring(3, 4)) > 2 ? 'done' : '',
        ]"
        @click="activateTab({ currentTab: 'tab2', type: 'tab' })"
      >
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
      <a
        href="#"
        class="tabLink"
        :class="[
          activeTab === 'tab3' ? 'active' : '',
          parseInt(this.activeTab.substring(3, 4)) > 3 ? 'done' : '',
        ]"
        @click="activateTab({ currentTab: 'tab3', type: 'tab' })"
      >
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
      <a
        href="#"
        class="tabLink"
        :class="[
          activeTab === 'tab4' ? 'active' : '',
          parseInt(this.activeTab.substring(3, 4)) > 4 ? 'done' : '',
        ]"
        @click="activateTab({ currentTab: 'tab4', type: 'tab' })"
      >
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
      <a
        href="#"
        class="tabLink"
        :class="[activeTab === 'tab5' ? 'active' : '']"
      >
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
    <div class="tabContent" v-if="this.activeTab === 'tab1'">
      <locationsTab class="location" @changeTab="activateTab" />
    </div>
    <div class="tabContent" v-if="this.activeTab === 'tab2'">
      <buildingTab class="building" @changeTab="activateTab" />
    </div>
    <div class="tabContent" v-if="this.activeTab === 'tab3'">
      <systemTab class="system" @changeTab="activateTab" />
    </div>
    <div class="tabContent" v-if="this.activeTab === 'tab4'">
      <dowelTab class="dowel" @changeTab="activateTab" />
    </div>
    <div class="tabContent" v-if="this.activeTab === 'tab5'">
      <resultTab class="result" @changeTab="activateTab" />
    </div>
  </div>
</template>

<script>
import locationsTab from "./components/LocationsTab.vue";
import buildingTab from "./components/BuildingTab.vue";
import systemTab from "./components/SystemTab.vue";
import dowelTab from "./components/DowelTab.vue";
import resultTab from "./components/ResultTab.vue";

export default {
  name: "DowelCalculator",
  components: {
    locationsTab,
    buildingTab,
    systemTab,
    dowelTab,
    resultTab,
  },
  data() {
    return {
      activeTab: "tab1",
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
    };
  },
  methods: {
    activateTab(_data) {
      const currentTab = _data.currentTab;
      const type = _data.type;
      if (type === "tab") {
        if (this.activeTab.substring(3, 4) > currentTab.substring(3, 4)) {
          this.activeTab = currentTab;
        }
      } else {
        this.activeTab = currentTab;
      }
    },
  },
  mounted() {
    this.$store.dispatch("getGlobals");
    this.$store.dispatch("getDefinitions");
    this.$store.dispatch("getSelectTree", "system");
  },
};
</script>

<style lang="scss"></style>
