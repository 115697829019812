<template>
  <div>
    <div class="mobilTabNavigation">
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab1', type: 'back' })"
      >
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
      <a href="#" class="tabLink active">
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
    </div>
    <h2 class="headline display-2">Gebäudegeometrie</h2>
    <div class="formGrid">
      <div class="w-full xl:w-1/3">
        <img :src="`${imgFolder}hausSkizze.svg`" />
      </div>
      <div class="w-full xl:w-1/3">
        <p>
          <label>Länge</label>
          <input type="number" v-model="geometry.length" /><span class="unit"
            >m</span
          >
        </p>
        <p>
          <label>Breite</label>
          <input type="number" v-model="geometry.width" /><span class="unit"
            >m</span
          >
        </p>
        <p>
          <label>Höhe</label>
          <input type="number" v-model="geometry.height" /><span class="unit"
            >m</span
          >
        </p>
        <p class="footnote">Die maximale Höhe beträgt {{ maxHeight }} Meter.</p>
      </div>
    </div>
    <div class="w-full btnWrapper">
      <button
        @click="$emit('changeTab', { currentTab: 'tab1', type: 'back' })"
        class="prevBtn"
      >
        Zurück
      </button>
      <button
        @click="$emit('changeTab', { currentTab: 'tab3', type: 'next' })"
        class="nextBtn"
        :disabled="nextClick"
      >
        Weiter
      </button>
    </div>
    <div class="mobilTabNavigation">
      <a href="#" class="tabLink">
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
      <a href="#" class="tabLink">
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locationData: {
        project: null,
        builder: null,
        street: null,
        zip: null,
        town: null,
        terrainCategory: null,
        windloadzone: null,
      },
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
    };
  },
  computed: {
    geometry: {
      get() {
        return this.$store.getters.getGeometry;
      },
      set() {
        this.$store.commit("updateGeometry", this.geometry);
      },
    },
    maxHeight() {
      let currentHeight = 25;
      if (
        this.locationData.windloadzone == 4 &&
        this.locationData.terrainCategory == 2
      ) {
        currentHeight = 10;
      }
      return currentHeight;
    },
    nextClick() {
      let isNotValid = false;
      if (this.geometry.length === null || this.geometry.length === "") {
        isNotValid = true;
      }
      if (this.geometry.width === null || this.geometry.width === "") {
        isNotValid = true;
      }
      if (
        this.geometry.height === null ||
        this.geometry.height === "" ||
        this.geometry.height > this.maxHeight
      ) {
        isNotValid = true;
      }
      return isNotValid;
    },
  },
  created() {
    this.locationData = this.$store.getters.getLocation;
  },
};
</script>

<style></style>
