<template>
  <div>
    <div class="mobilTabNavigation">
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab1', type: 'back' })"
      >
        <span class="headline number">1</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Standortdaten</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab2', type: 'back' })"
      >
        <span class="headline number">2</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Gebäudegeometrie</span>
      </a>
      <a
        href="#"
        class="tabLink"
        @click="$emit('changeTab', { currentTab: 'tab3', type: 'back' })"
      >
        <span class="headline number">3</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Systemauswahl</span>
      </a>
      <a href="#" class="tabLink active">
        <span class="headline number">4</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Dübelauswahl</span>
      </a>
    </div>
    <h2 class="headline display-2">Dübelauswahl</h2>
    <div class="formGrid">
      <div class="w-full xl:w-1/3">
        <img
          :src="
            this.dowelData.asset === undefined
              ? this.defaultImage
              : this.dowelData.asset
          "
        />
        <span class="imageSubline" v-if="this.dowelData.label !== ''">{{
          this.dowelData.label
        }}</span>
        <div v-show="showPlateImage" class="absolute w-64">
          <img
            :src="
              this.dowelData.asset === undefined
                ? this.defaultImage
                : this.dowel.plateAsset
            "
          />
          <span class="imageSubline" v-if="this.dowel.plateName !== ''">{{
            this.dowel.plateName
          }}</span>
        </div>
      </div>
      <div class="w-full xl:w-1/3">
        <p>
          <label>Verankerungsgrund</label>
          <Multiselect
            ref="anchorground"
            v-model="dowel.anchorground"
            placeholder="Bitte wählen"
            :options="this.anchorgroundValues"
            :searchable="this.anchorgroundValues > 3 ? true : false"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(2)"
            @clear="resetSelects(['assembly', 'dowel', 'plate'], 2)"
          />
        </p>
        <p>
          <label>Dübelmontage</label>
          <Multiselect
            ref="assembly"
            v-model="dowel.assembly"
            placeholder="Bitte wählen"
            :options="this.selectValues.assembly.values"
            :disabled="
              this.dowel.assembly !== '' || innerStep >= 2 ? false : true
            "
            :searchable="false"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(3)"
            @clear="resetSelects(['dowel', 'plate'], 3)"
          />
        </p>
        <p>
          <label>Dübelauswahl</label>
          <Multiselect
            ref="dowel"
            v-model="dowel.dowel"
            placeholder="Bitte
          wählen"
            :options="this.selectValues.dowel.values"
            :canDeselect="false"
            :canClear="false"
            @select="nextStep(4)"
            :disabled="this.dowel.dowel !== '' || innerStep >= 3 ? false : true"
            :searchable="false"
          />
        </p>
        <p
          v-if="
            (innerStep >= 4 || this.dowel.plate !== null) &&
            platesSelection === true
          "
        >
          <label>Dübelteller</label>
          <Multiselect
            ref="plate"
            v-model="dowel.plate"
            placeholder="Bitte
          wählen"
            :options="this.selectValues.plate.values"
            :canDeselect="false"
            :canClear="false"
            @select="selectPlate()"
            :searchable="false"
          />
        </p>
      </div>
      <div class="w-full xl:w-1/3">
        <p v-if="this.dowel.anchorground === 'unknown'">
          <label for="">Freie Eingabe</label>
          <input type="number" v-model="dowel.freeLoadcapacity" /><span
            class="unit"
            >kN</span
          >
        </p>
        <p v-if="this.dowel.anchorground === 'unknown'" class="footnote">
          Eingabe der charakteristischen Tragfähigkeit (N<sub>Rk</sub>) des
          Dübels nach erfolgter Auswertung der Dübelauszugsversuche am
          Bauvorhaben. Um eine fehlerfreie Konfiguration zu gewährleisten sollte
          Ihr Wert über 0,45 kN liegen.
        </p>
      </div>
    </div>
    <div class="w-full btnWrapper">
      <button
        @click="$emit('changeTab', { currentTab: 'tab3', type: 'back' })"
        class="prevBtn"
      >
        Zurück
      </button>
      <button
        @click="$emit('changeTab', { currentTab: 'tab5', type: 'next' })"
        class="nextBtn"
        :disabled="nextClick"
      >
        Weiter
      </button>
    </div>
    <div class="mobilTabNavigation">
      <a href="#" class="tabLink">
        <span class="headline number">5</span>
        <img :src="`${imgFolder}nav-arrow.svg`" />
        <span class="">Ergebnis</span>
      </a>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      defaultImage:
        "https://www.knauf.de/cutout/cutout_1109172_detail_picture_1.jpg",
      innerStep: 1,
      selectValues: {},
      anchorgroundKey: "",
      showPlateImage: false,
      currentChildren: {
        anchorground: {},
        assembly: {},
        dowel: {},
        plate: {},
      },
      systemData: {},
      stepData: {},
      platesSelection: false,
      possibleDowels: {},
      imgFolder: process.env.VUE_APP_FRONTEND_PATH + "images/",
    };
  },
  computed: {
    dowel: {
      get() {
        return this.$store.getters.getDowel;
      },
      set() {
        this.$store.commit("updateDowel", this.dowel);
      },
    },
    dowelData() {
      let doweldata = {
        label: "",
        asset: this.defaultImage,
      };
      if (this.dowel.dowel !== null) {
        doweldata = this.findInList(
          this.selectValues["dowel"]["values"],
          "value",
          this.dowel.dowel
        );
      }
      return doweldata;
    },
    currentDowelChildren: {
      get() {
        return this.$store.getters.getDowelChildren;
      },
      set() {
        this.$store.commit("updateDowelChildren", this.currentChildren);
      },
    },
    anchorgroundValues() {
      return this.selectValues[this.anchorgroundKey].values;
    },
    nextClick() {
      let isNotValid = false;
      if (this.dowel.dowel === "" || this.dowel.dowel === null) {
        isNotValid = true;
      }
      if (
        this.platesSelection === true &&
        (this.dowel.plate === "" || this.dowel.plate === null)
      ) {
        isNotValid = true;
      }
      if (
        this.dowel.anchorground === "unknown" &&
        (this.dowel.freeLoadcapacity === null ||
          this.dowel.freeLoadcapacity < 0.45)
      ) {
        isNotValid = true;
      }
      return isNotValid;
    },
  },
  created() {
    this.systemData = this.$store.getters.getSystem;
    this.selectValues = this.$store.getters.getDefinitions;
    this.anchorgroundKey = this.$store.getters.getAnchorGroundKey;
    if (this.currentDowelChildren.assembly.length !== undefined) {
      this.currentChildren = this.currentDowelChildren;
      this.getPossibleDowels();
      this.innerStep = 4;
      if (
        this.currentDowelChildren.plate !== undefined &&
        this.currentDowelChildren.plate.length !== 0
      ) {
        this.platesSelection = true;
      }
    } else {
      this.$store.dispatch("getDowelSelectTree", [
        "dowel",
        this.systemData.insulation,
        this.systemData.system.id,
        this.systemData.thickness,
      ]);
      this.nextStep(1);
    }
  },
  methods: {
    resetSelects(resetter, step) {
      this.innerStep = step;
      this.showPlateImage = false;
      if (step === 2) {
        this.dowel.assembly = "";
        this.dowel.dowel = null;
        this.dowel.plate = "";
        this.dowel.plateName = "";
      }
      if (step === 3) {
        this.dowel.dowel = null;
        this.dowel.plate = "";
        this.dowel.plateName = "";
      }
      if (step === 4) {
        this.dowel.plate = "";
        this.dowel.plateName = "";
      }
      resetter.forEach((element) => {
        this.disableOptions(this.selectValues[element].values);
        if (this.$refs[element]) {
          this.$refs[element].setPointer(false);
        }
      });
      this.platesSelection = false;
    },
    findInList(source, sourceKey, id) {
      for (const key in source) {
        const item = source[key];
        if (item[sourceKey] === id) {
          return item;
        }
        if (item.children) {
          const subresult = this.findInList(item.children, sourceKey, id);
          if (subresult) {
            return subresult;
          }
        }
      }
    },
    nextStep(step) {
      this.innerStep = step;
      this.stepData = this.$store.getters.getDowelTree;
      if (step === 1) {
        for (
          let i = 0, l = this.selectValues[this.anchorgroundKey].values.length;
          i < l;
          i += 1
        ) {
          const item = this.selectValues[this.anchorgroundKey].values[i];
          //Ausname Verankerungsgrund Holzbau Holzfaser - Holzbau / Massiver Holzuntergrund bei System 61bafba30283a
          //Siehe Email von Carsten Böhme am 11.4.2022 - 9:25 Uhr
          if (this.anchorgroundKey === "anchor_base_wood_fiber") {
            if (this.systemData.ground === "g_3" && item.value !== "abwf_1") {
              item.disabled = false;
            } else if (
              this.systemData.ground !== "g_3" &&
              item.value !== "abwf_2"
            ) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
          } else {
            item.disabled = false;
          }
        }
        this.resetSelects(["assembly", "dowel", "plate"], 1);
      } else if (step === 2) {
        //get possible dowel to anchorground
        this.getPossibleDowels();
        //activate dowel Assembly
        this.resetSelects(["assembly", "dowel", "plate"], 2);
        for (
          let i = 0, l = this.selectValues["assembly"].values.length;
          i < l;
          i += 1
        ) {
          const item = this.selectValues["assembly"].values[i];
          const elem = this.stepData.find((elem) => elem.id === item.value);
          if (elem) {
            item.disabled = false;
          }
        }
        this.currentChildren.assembly = this.stepData;
      } else if (step === 3) {
        this.resetSelects(["dowel", "plate"], 3);
        const currentStep = this.findInList(
          this.stepData,
          "id",
          this.dowel.assembly
        );
        this.currentChildren.dowel = currentStep.dowels;
        for (
          let i = 0, l = this.selectValues["dowel"].values.length;
          i < l;
          i += 1
        ) {
          const item = this.selectValues["dowel"].values[i];
          const elem = currentStep.dowels.find(
            (elem) => elem.id === item.value
          );
          if (elem) {
            // console.log(elem.id);
            // console.log(this.possibleDowels);
            const checkPossibleDowels = this.possibleDowels.find(
              (dowels) => dowels === elem.id
            );
            if (checkPossibleDowels) {
              item.disabled = false;
            }
          }
        }
      } else if (step === 4) {
        //check if plates in selected dowel
        this.resetSelects(["plate"], 4);
        const currentStep4 = this.findInList(
          this.currentChildren.dowel,
          "id",
          this.dowel.dowel
        );
        this.currentChildren.plate = currentStep4.plates;
        if (currentStep4.plates) {
          for (
            let i = 0, l = this.selectValues["plate"].values.length;
            i < l;
            i += 1
          ) {
            const item = this.selectValues["plate"].values[i];
            const elem = currentStep4.plates.find(
              (elem) => elem.id === item.value
            );
            if (elem) {
              item.disabled = false;
            }
          }
          this.platesSelection = true;
        } else {
          this.platesSelection = false;
        }
      }
      this.currentDowelChildren = this.currentChildren;
    },
    getPossibleDowels() {
      fetch(
        process.env.VUE_APP_PATH +
          "get/dowels-anchorground/" +
          this.dowel.anchorground
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          this.possibleDowels = responseJSON;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    disableOptions(list) {
      for (let i = 0, l = list.length; i < l; i += 1) {
        const item = list[i];
        item.disabled = true;
      }
    },
    selectPlate() {
      if (this.dowel.plate !== "p_1") {
        this.showPlateImage = true;
        const platedata = this.findInList(
          this.currentDowelChildren.plate,
          "id",
          this.dowel.plate
        );
        this.dowel.plateName = platedata.name;
        this.dowel.plateAsset = platedata.asset;
      } else {
        this.showPlateImage = false;
        this.dowel.plateName = "";
        this.dowel.plateAsset = "";
      }
    },
  },
};

const scrollElements = document.querySelectorAll(".knauf-table .content");
for (let i = 0, l = scrollElements.length; i < l; i += 1) {
  scrollElements[i].addEventListener("scroll", function (e) {
    setPos(e.target.scrollLeft, e.target.parentNode.parentNode.id);
  });
}
const setPos = function (_pos, _tableID) {
  for (let i = 0, l = scrollElements.length; i < l; i += 1) {
    if (scrollElements[i].parentNode.parentNode.id === _tableID) {
      scrollElements[i].scrollLeft = _pos;
    }
  }
};

const tableTabs = document.querySelectorAll(".knauf-tab-nav ul a");
for (let i = 0, l = tableTabs.length; i < l; i += 1) {
  tableTabs[i].addEventListener("click", function (e) {
    e.preventDefault();
    const currentTabsLis = e.target.parentNode.parentNode.children;
    for (let j = 0, len = currentTabsLis.length; j < len; j += 1) {
      currentTabsLis[j].children[0].classList.remove("active");
      document
        .getElementById(currentTabsLis[j].children[0].dataset.tab)
        .classList.remove("active");
    }
    e.target.classList.add("active");
    document.getElementById(e.target.dataset.tab).classList.add("active");
    e.target.parentNode.parentNode.previousElementSibling.innerHTML =
      e.target.innerHTML;
    if (e.target.parentNode.parentNode.classList.contains("open")) {
      e.target.parentNode.parentNode.classList.remove("open");
    }
  });
}

const selectOpener = document.querySelectorAll(".knauf-tab-nav .selectOpener");
for (let i = 0, l = selectOpener.length; i < l; i += 1) {
  selectOpener[i].addEventListener("click", function (e) {
    e.preventDefault();
    e.target.nextElementSibling.classList.toggle("open");
  });
}
</script>

<style></style>
